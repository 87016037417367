<template>
  <app-modal-withdraw
    :show="showModalWithdraw"
    @close="showModalWithdraw = false"
    @refresh="refresh"
  />
  <div>
    <div class="px-4 p-2">
      <div
        class="
          px-5
          py-2
          flex
          m-auto
          lg:px-0
          container
          items-center
          justify-between
        "
      >
        <div>
          <p class="font-medium">Payout</p>
        </div>
      </div>
    </div>
  </div>
  <app-modal-payout-detail
    :show="showModalDetail"
    @close="showModalDetail = false"
    :payout="selectPayout"
    :showBusiness="true"
    processedPaymentLinkRoute="support.balance.transaction.payment-link"
    @update:payout="selectPayout = $event"
  />
  <app-table
    :tabs="tabs"
    :loading="apiPaginationLoading"
    :apiResponse="apiPaginationResponse?.data ?? []"
    @params-changed="paramsChanged"
    :showEmptyButton="false"
    :filters="[
      {
        key: 'filter[business_name]',
        type: 'text',
        placeholder: $t('payout.table.account.header_business_name'),
      },
      {
        key: 'filter[bank_id]',
        type: 'select',
        placeholder: $t('payout.table.account.header_bank_name'),
        items: banks,
      },
      {
        key: 'filter[account_no]',
        type: 'text',
        placeholder: $t('payout.table.account.header_acc_no'),
      },
      {
        key: 'filter[amount]',
        type: 'number',
        placeholder: $t('payout.table.account.header_amount'),
      },
      {
        key: 'filter[currency]',
        type: 'text',
        placeholder: 'Currency',
      },
      {
        key: 'filter[created_at_between]',
        type: 'date',
        placeholder: 'Created At',
        value: '',
      },
    ]"
    @on-item-click="onItemClick"
  >
    <template v-slot:header>
      <td>
        <b>{{ $t("wallet.payout.business_name") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.bank_name") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.account_no") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.date") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.amount") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.status") }}</b>
      </td>
    </template>

    <template v-slot:body="data">
      <td>
        {{ data.model.business?.name ?? "-" }}
      </td>
      <td>
        {{ data.model.business.bank?.bank.display_name ?? "-" }}
      </td>
      <td>
        {{ data.model.business.bank?.account_no ?? "-" }}
      </td>
      <td>
        {{ $moment(data.model.created_at).format("DD MMM YYYY hh:mm a") }}
      </td>
      <td>
        {{ $formats.currency(data.model.currency, data.model.amount ?? 0.0) }}
      </td>
      <td>
        <app-badge
          class="capitalize w-24"
          :status="getStatus(data.model?.status)"
        >
          {{ data.model?.status ?? "-" }}
        </app-badge>
      </td>
    </template>
  </app-table>
</template>

<script>
import PAYOUT_STATUS from "@/utils/const/payout_status";

export default {
  data() {
    return {
      tabs: [],
      current_tab: null,
      filters: {},
      showModalDetail: false,
      showModalWithdraw: false,
      selectPayout: null,
    };
  },
  computed: {
    apiPaginationResponse() {
      return this.$store.getters["walletStore/apiPaginationResponse"];
    },

    apiPaginationLoading() {
      return this.$store.getters["walletStore/apiPaginationLoading"];
    },

    banks() {
      return this.$store.getters["referenceStore/banks"];
    },
  },

  mounted() {
    this.fetchListPayout();

    this.$store.dispatch("referenceStore/getBanks", 0);

    this.tabs = [
      { key: "requested", title: this.$t("payout.table.merchant.tab_request") },
      { key: "processing", title: this.$t("support.payout.tab_settlement") },
      { key: "approved", title: this.$t("support.payout.tab_approved") },
      { key: "rejected", title: this.$t("support.payout.tab_failed") },
    ];
  },
  methods: {
    getStatus(status) {
      switch (status) {
        case PAYOUT_STATUS.APPROVED:
          return "success";
        case PAYOUT_STATUS.PROCESSING:
          return "light";
        case PAYOUT_STATUS.REQUESTED:
          return "light";
        case PAYOUT_STATUS.REJECTED:
          return "error";
      }
    },

    paramsChanged(filters, queryParams) {
      this.filters = filters;
      this.filters["filter[bank_id]"] = filters["filter[bank_id]"]?.id;
      this.filterFormatDate(filters["filter[created_at_between]"]);
      this.fetchListPayout();
    },

    filterFormatDate(date) {
      let start = date?.start_date;
      let end = date?.end_date;

      if (date) this.filters["filter[created_at_between]"] = start + "," + end;
    },

    fetchListPayout() {
      this.getTabStatusTab();
      this.$store.dispatch("walletStore/getPayoutForSupport", this.filters);
    },

    refresh() {
      window.location.href = this.$route.path;
    },

    getTabStatusTab() {
      var status_tab = null;
      let currentTab = this.current_tab ?? this.$route.query.tab;

      if (currentTab == PAYOUT_STATUS.APPROVED) {
        status_tab = PAYOUT_STATUS.APPROVED;
      } else if (currentTab == PAYOUT_STATUS.PROCESSING) {
        status_tab = PAYOUT_STATUS.PROCESSING;
      } else if (currentTab == PAYOUT_STATUS.REQUESTED) {
        status_tab = PAYOUT_STATUS.REQUESTED;
      } else if (currentTab == PAYOUT_STATUS.REJECTED) {
        status_tab = PAYOUT_STATUS.REJECTED;
      } else {
        status_tab = PAYOUT_STATUS.REQUESTED;
      }

      this.filters["filter[status]"] = status_tab;
    },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectPayout = data;
    },
  },
};
</script>